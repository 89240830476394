import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "../styles/coOrdinates.scss";
import logo from "../assets/img/digispace.svg";
import noMap from "../assets/img/pocket-living-offices-threefold-architects-london-uk_dezeen_floor-plan 1.png";
import CanvasComponent from "../components/CanvasComponent";
import upArrow from "../assets/img/upArrow.svg";
import downArrow from "../assets/img/downArrow.svg";
import { generatePDF } from "../utils/generatePDF";
import { generateJSON } from "../utils/generateJSON";

const CoOrdinateHome = () => {
  const [imageurl, setImageUrl] = useState();
  const [width, setwidth] = useState(); //set Width of Map Container
  const [height, setheight] = useState(); //set Height of Map Container
  const ref = useRef(null); // reference to map container
  const [markedZones, setMarkedZones] = useState([]); // set Marked Zones
  const [selectedZone, setSelectedZone] = useState(); // set Selected Zone
  const [roomName, setRoomName] = useState(); // set Room Name
  const [active, setActive] = useState(""); // set Active State
  const [assetsName, setAssetsName] = useState(); // set Assets
  const [selectedDevice, setSelectedDevice] = useState(); // set Selected Device
  const [roomAssets, setRoomAssets] = useState(); // set Assets
  const [encodedImage, setEncodedImage] = useState(); // set Encoded Image
  const [fileName, setFileName] = useState(); // set File Name
  const [removeRoom, setRemoveRoom] = useState(false); // set Remove Room

  //when page is loaded set width and height accordingly to map container
  useLayoutEffect(() => {
    setwidth(ref?.current?.offsetWidth);
    setheight(ref?.current?.offsetHeight);
  }, [ref, imageurl]);

  //  on every resize of page set height and width of map container in state and update value
  useEffect(() => {
    const handleSize = () => {
      setwidth(ref?.current?.offsetWidth);
      setheight(ref?.current?.offsetHeight);
    };
    window.addEventListener("resize", handleSize);
    return () => {
      window.addEventListener("resize", handleSize);
    };
  }, [imageurl]);

  // handleFileUpload is a function that is triggered when a file is uploaded
  const handleFileUpload = (e) => {
    // Check if the first file in the files array exists
    if (e.target.files[0]) {
      setFileName(e.target.files[0]?.name);
      // Create a URL representing the uploaded file
      const ImageUrl = URL.createObjectURL(e.target.files[0]);
      // Set the state of imageUrl with the created URL
      setImageUrl(ImageUrl);

      // Create a new FileReader object
      let reader = new FileReader();
      // Read the uploaded file as a Data URL
      reader.readAsDataURL(e.target.files[0]);
      // When the file reading is finished, set the state of imageUrl with the base64 encoded string
      reader.onloadend = function () {
        setEncodedImage(reader.result);
      };
    }
  };

  // addRoom is a function that is used to add a new room to the marked zones
  const addRoom = () => {
    // Check if roomName is not empty
    if (roomName) {
      // Create a new markedZone object
      // This object has the same x and y as the selecedZone
      // The roomName of this object is the roomName from the state
      // The assets array of this object is an empty array
      const markedZone = {
        x: selectedZone.x,
        y: selectedZone.y,
        showx: selectedZone.showx,
        showy: selectedZone.showy,
        roomName: roomName,
        devices: [],
      };

      // Update the markedZones state
      // The setMarkedZones function is called with a function as its argument
      // This function takes the previous state (prevZones) as its parameter and returns a new array that contains all the previous zones and the new markedZone
      setMarkedZones((prevZones) => [...prevZones, markedZone]);

      // Reset the selectedZone state
      setSelectedZone();
    }
  };

  // addAsset is a function that is used to add a new asset to a marked zone
  const addAsset = () => {
    // Check if assetsName is not empty
    if (assetsName) {
      // Create a new markedZone object
      // This object has the same x, y, and roomName as the roomAssets of selectedDevice
      // The assets array of this object is a new array that contains all the assets of the roomAssets of selectedDevice and a new asset with the x, y, and deviceName of selectedDevice
      const markedZone = {
        x: Math.round(selectedDevice.roomAssets.x),
        y: Math.round(selectedDevice.roomAssets.y),
        showx: selectedDevice.roomAssets.showx,
        showy: selectedDevice.roomAssets.showy,
        roomName: selectedDevice.roomAssets.roomName,
        devices: [
          ...selectedDevice.roomAssets.devices,
          {
            x: Math.round(selectedDevice.x),
            y: Math.round(selectedDevice.y),
            showx: selectedDevice.showx,
            showy: selectedDevice.showy,
            deviceName: assetsName,
          },
        ],
      };

      // Update the markedZones state
      // For each zone in markedZones, if the roomName of the zone is the same as the roomName of the roomAssets of selectedDevice, replace the zone with markedZone
      // Otherwise, leave the zone unchanged
      setMarkedZones(
        markedZones.map((zone) =>
          zone.roomName === selectedDevice.roomAssets.roomName
            ? markedZone
            : zone
        )
      );

      // Reset the selectedDevice state
      setSelectedDevice();

      // Update the roomAssets state with markedZone
      setRoomAssets(markedZone);

      // Reset the assetsName state
      setAssetsName("");
    }
  };

  // handleRoomRemove is a function that is used to remove a room from the marked zones
  const handleRoomRemove = (zone) => {
    if (window.confirm("Are you sure you want to delete this room?")) {
      setMarkedZones(markedZones.filter((item) => item !== zone));
      setActive("");
      setRoomAssets();
      setSelectedDevice();
      setRemoveRoom(true);
    } else {
      return;
    }
  };

  const removeDevice = (device, room) => {
    if (window.confirm("Are you sure you want to delete this Assets?")) {
      setMarkedZones(
        markedZones?.map((zone) => {
          if (zone?.roomName === room.roomName) {
            return {
              ...zone,
              devices: zone.devices.filter(
                (item) => item.deviceName !== device.deviceName
              ),
            };
          }
          return zone;
        })
      );
      setRoomAssets();
      setSelectedDevice();
      setRemoveRoom(true);
    } else {
      return;
    }
  };

  return (
    <div className="coOrdinateContainer">
      {/* map UI  */}
      <div className="Map_container" ref={ref}>
        {!imageurl ? (
          <div className="No_map_container">
            <img src={noMap} alt="no_map" />
            <p>Please Upload the Floor Map Image to Add Rooms and Assets</p>
          </div>
        ) : (
          <CanvasComponent
            imageurl={imageurl}
            width={width}
            height={height}
            setSelectedZone={setSelectedZone}
            roomAssets={roomAssets}
            selectedZone={selectedZone}
            selectedDevice={selectedDevice}
            setSelectedDevice={setSelectedDevice}
            markedZone={markedZones}
            setRemoveRoom={setRemoveRoom}
            removeRoom={removeRoom}
          />
        )}
      </div>

      {/* sidebar UI  */}
      <div className="sidebar_Container">
        <img src={logo} alt="logo" />
        <p>Floor Co-ordinate Generator</p>
        <div className="upload_input_container">
          <input
            onChange={handleFileUpload}
            name="file-upload"
            type="file"
            accept="image/*"
          />
        </div>

        <div className="mid_section">
          {imageurl && (
            <div>
              <p className="Room_heading">All Rooms</p>
              {markedZones?.length <= 0 && (
                <p className="sub_head">
                  You don’t have any room yet, click on the map to create a room
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.6rem",
                }}
              >
                {markedZones?.map((zone, index) => (
                  <div
                    className="room_card_container"
                    style={{
                      border:
                        active === zone.roomName ? "2px solid #7F2F92" : "",
                    }}
                  >
                    <div className="room_card" key={index}>
                      <div style={{ display: "flex", gap: "0.8rem" }}>
                        {/* <input type="checkbox" /> */}
                        <p>{zone.roomName}</p>
                      </div>

                      {active === zone.roomName ? (
                        <button
                          //   className="room_editdown_btn"
                          onClick={() => {
                            setActive("");
                            setRoomAssets();
                            setSelectedDevice();
                          }}
                        >
                          <img src={upArrow} alt="edit" />
                        </button>
                      ) : (
                        <button
                          //   className="room_editup_btn"
                          onClick={() => {
                            setActive(zone.roomName);
                            setRoomAssets(zone);
                          }}
                        >
                          <img src={downArrow} alt="edit" />
                        </button>
                      )}
                    </div>
                    {active === zone?.roomName &&
                      zone?.devices?.length <= 0 && (
                        <p className="sub_head">
                          You don’t have any asset yet, click on the map to
                          create an asset
                        </p>
                      )}
                    {active === zone?.roomName && zone?.devices.length >= 1 && (
                      <div className="assets_card">
                        {active === zone?.roomName &&
                          zone?.devices?.map((device, index) => (
                            <div className="assets_card_box" key={index}>
                              <div
                                className="remove_device"
                                onClick={() => removeDevice(device, zone)}
                              >
                                x
                              </div>
                              <div style={{ display: "flex", gap: "0.8rem" }}>
                                <p>{device.deviceName}</p>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                    {active === zone?.roomName && (
                      <div className="remove_btn_div">
                        <p
                          style={{ fontSize: "0.8rem", cursor: "pointer" }}
                          className="room_remove_btn"
                          onClick={() => handleRoomRemove(zone)}
                        >
                          Delete {zone?.roomName}
                        </p>
                      </div>
                    )}
                    {selectedDevice && active === zone?.roomName ? (
                      <div
                        className="room_input"
                        style={{
                          border: selectedDevice ? "2px solid purple" : "",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Enter Assets Name"
                          className="room_inputBox"
                          onChange={(e) => setAssetsName(e.target.value)}
                          autoFocus
                        />
                        <button className="room_add_btn" onClick={addAsset}>
                          Add Assets
                        </button>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
              {selectedZone && (
                <div
                  className="room_input"
                  style={{ border: selectedZone ? "2px solid purple" : "" }}
                >
                  <input
                    type="text"
                    placeholder="Enter Room Name"
                    className="room_inputBox"
                    onChange={(e) => setRoomName(e.target.value)}
                    autoFocus
                  />
                  <button className="room_add_btn" onClick={addRoom}>
                    Add Room
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        {markedZones?.length >= 1 && (
          <button
            className="export_btn"
            onClick={() => generateJSON(encodedImage, fileName, markedZones)}
          >
            Get Map co-ordinate data
          </button>
        )}
      </div>
    </div>
  );
};

export default CoOrdinateHome;
