export const generateJSON = (encodedImage, fileName, markedZones) => {
  console.log(markedZones)
 // Create a new array to store modified zones
 const modifiedZones = markedZones.map(zone => {
  const modifiedZone = { ...zone }; // Create a shallow copy of the zone object
  delete modifiedZone.showx;
  delete modifiedZone.showy;

  // Remove showx and showy properties from devices array
  if (modifiedZone.devices) {
    modifiedZone.devices = modifiedZone.devices.map(device => {
      const modifiedDevice = { ...device }; // Create a shallow copy of the device object
      delete modifiedDevice.showx;
      delete modifiedDevice.showy;
      return modifiedDevice;
    });
  }

  return modifiedZone;
});
  const data = {
    "floor_map": {
      "name": fileName,
      "encode": encodedImage,
    },
    "marked_zones": modifiedZones,
  };

  const jsonData = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonData], { type: "application/json" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "Map_co-Ordinates_data.json";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
