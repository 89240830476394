import React, { useEffect, useRef } from "react";
import "../styles/canvas.scss";

// CanvasComponent is a functional component that takes several props including imageurl, width, height, roomAssets, setSelectedZone, and setSelectedDevice
const CanvasComponent = ({
  imageurl,
  width,
  height,
  roomAssets,
  setSelectedZone,
  setSelectedDevice,
  selectedZone,
  selectedDevice,
  markedZone,
  setRemoveRoom,
  removeRoom,
}) => {
  // useRef is used to create references to the canvas, image, image position, and context
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const imagePosition = useRef({ x: 0, y: 0 });
  const ctxRef = useRef(null);

  // useEffect is used to perform side effects in function components
  // In this case, it's used to load the image and set the image position when the imageurl prop changes
  useEffect(() => {
    imageRef.current = new Image();
    imageRef.current.src = imageurl;
    imageRef.current.onload = handleImageLoad;
    imagePosition.current = { x: 0, y: 0 }; // Reset image position
  }, [imageurl]);

  useEffect(() => {
    if (imageRef.current && removeRoom) {
      const ctx = ctxRef.current;
      const canvas = canvasRef.current;

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw the image
      const image = imageRef.current;
      const scaleFactor = Math.min(
        canvas.width / image.width,
        canvas.height / image.height
      );
      const newWidth = image.width * scaleFactor;
      const newHeight = image.height * scaleFactor;
      const offsetX = (canvas.width - newWidth) / 2;
      const offsetY = (canvas.height - newHeight) / 2;
      ctx.drawImage(image, offsetX, offsetY, newWidth, newHeight);
      // Draw the circles
      markedZone?.forEach((circle) => {
        console.log(circle)
        drawCircle(ctx, circle.showx, circle.showy);
        circle?.devices?.forEach((device) => {
          drawdeviceCircle(ctx, device.showx, device.showy);
        })
      });

      setRemoveRoom(false);
    }
  }, [markedZone, removeRoom]);

  // this function work when we click on the room
  //and we get the x and y coordinates of the room and set the selected device
  // handleImageLoad is called when the image is loaded
  // It sets the canvas dimensions, clears the canvas, calculates the scaling factor for the image, calculates the new dimensions of the image after scaling, calculates the offset to center the scaled image within the canvas, and draws the image at the calculated position
  const handleImageLoad = () => {
    const canvas = canvasRef.current;
    if (canvas && imageRef.current) {
      const ctx = canvas.getContext("2d");
      const image = imageRef.current;

      canvas.width = width;
      canvas.height = height - 5;

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Calculate the scaling factor for the image
      const scaleFactor = Math.min(
        canvas.width / image.width,
        canvas.height / image.height
      );

      // Calculate the new dimensions of the image after scaling
      const newWidth = image.width * scaleFactor;
      const newHeight = image.height * scaleFactor;

      // Calculate the offset to center the scaled image within the canvas
      const offsetX = (canvas.width - newWidth) / 2;
      const offsetY = (canvas.height - newHeight) / 2;

      // Draw the image at the calculated position
      ctx.drawImage(image, offsetX, offsetY, newWidth, newHeight);

      // Set the context to the ref
      ctxRef.current = ctx;
    }
  };

  // handleRoomClick is called when a room is clicked
  // It calculates the scaled coordinates of the click, stores the original image coordinates, sets the selected device, and draws a circle at the scaled coordinates
  const handleRoomClick = (event) => {
    const canvas = canvasRef.current;
    const ctx = ctxRef.current;
    if (canvas && ctx) {
      const rect = canvas.getBoundingClientRect();
      const scaleX = imageRef.current.width / canvas.width; // Calculate the scale for X coordinate
      const scaleY = imageRef.current.height / canvas.height; // Calculate the scale for Y coordinate
      const x = (event.clientX - rect.left + window.pageXOffset) * scaleX; // Scale X coordinate to original image size
      const y = (event.clientY - rect.top + window.pageYOffset) * scaleY; // Scale Y coordinate to original image size
      const showx = event.nativeEvent.offsetX - imagePosition.current.x;
      const showy = event.nativeEvent.offsetY - imagePosition.current.y;
      // Store the original image coordinates
      const markedZone = { x, y, showx, showy };
      setSelectedDevice({ ...markedZone, roomAssets });
      drawdeviceCircle(ctx, showx, showy); // Draw circle at scaled coordinates
    }
  };

  // drawdeviceCircle is used to draw a circle at the given coordinates
  const drawdeviceCircle = (ctx, x, y) => {
    const radius = 10; // Radius for your circle
    const borderWidth = 2; // Border width

    ctx.beginPath();
    ctx.arc(
      x + imagePosition.current.x,
      y + imagePosition.current.y,
      radius,
      0,
      2 * Math.PI
    );
    ctx.fillStyle = "red";
    ctx.fill();

    ctx.strokeStyle = "white";
    ctx.lineWidth = borderWidth;
    ctx.stroke();

    // Add text for the zone name
    ctx.font = "12px Arial";
    // ctx.fillText( x + imagePosition.current.x - 10, y + imagePosition.current.y - 15);
  };

  // handleCanvasClick is called when the canvas is clicked
  // It calculates the scaled coordinates of the click, stores the original image coordinates, sets the selected zone, and draws a circle at the scaled coordinates
  const handleCanvasClick = (event) => {
    const canvas = canvasRef.current;
    const ctx = ctxRef.current;
    if (canvas && ctx) {
      const rect = canvas.getBoundingClientRect();
      const scaleX = imageRef.current.width / canvas.width; // Calculate the scale for X coordinate
      const scaleY = imageRef.current.height / canvas.height; // Calculate the scale for Y coordinate
      const x = (event.clientX - rect.left + window.pageXOffset) * scaleX; // Scale X coordinate to original image size
      const y = (event.clientY - rect.top + window.pageYOffset) * scaleY; // Scale Y coordinate to original image size
      const showx = event.nativeEvent.offsetX - imagePosition.current.x;
      const showy = event.nativeEvent.offsetY - imagePosition.current.y;
      // Store the original image coordinates
      const markedZone = { x, y, showx, showy };
      setSelectedZone(markedZone);
      drawCircle(ctx, showx, showy); // Draw circle at scaled coordinates
    }
  };

  // drawCircle is used to draw a circle at the given coordinates
  const drawCircle = (ctx, x, y, zoneName) => {
    const radius = 140; // Radius for your circle
    const roomX = x;
    const roomY = y;
    const gradient = ctx.createLinearGradient(
      roomX - 50,
      roomY,
      roomX + 50,
      roomY
    );
    gradient.addColorStop(0, "#B575C4"); // Start color
    gradient.addColorStop(1, "#7F2F92"); // End color
    // Draw Dot
    ctx.beginPath();
    ctx.arc(
      x + imagePosition.current.x,
      y + imagePosition.current.y,
      radius,
      0,
      2 * Math.PI
    );
    ctx.fillStyle = "rgba(181, 117, 196, 0.2)";
    ctx.fill();
    ctx.strokeStyle = "rgba(181, 117, 196, 0.2)"; // You can set your desired border color
    ctx.lineWidth = 2; // You can set your desired border width
    ctx.stroke();
    ctx.closePath();

    // Draw device circle on top
    // drawdeviceCircle(ctx, x, y);
  };

  // The component returns a canvas element with an onClick handler that calls handleCanvasClick or handleRoomClick depending on whether roomAssets is truthy
  return (
    <canvas
      id="canvas"
      ref={canvasRef}
      onClick={(e) => {
        if (!roomAssets && !selectedZone) {
          handleCanvasClick(e);
        } else if (roomAssets && !selectedDevice) {
          handleRoomClick(e);
        } else {
          alert("Add Selected Room/Asset first to add another one.");
        }
      }}
    />
  );
};

export default CanvasComponent;
